<template>
  <v-dialog v-model="dialog.show" max-width="600">
    <v-card>
      <!-- Dialog Title -->
      <v-card-title class="headline">
        New Slide
      </v-card-title>
      <!-- Slide -->
      <div class="dialog-slide">
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- Slide Title -->
          <v-text-field v-model="slide.slideTitle" label="Slide Title" outlined :rules="rules.slideTitle"/>
          <!-- Slide Type -->
          <!-- <v-select v-model="slide.slideType" :items="slidesType" label="Layout Type" outlined required :rules="rules.slideType"/> -->
        </v-form>
      </div>
      <!-- Dialog Actions -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="saveNewSlide">
          <v-progress-circular color="white" v-if="progress" indeterminate></v-progress-circular>
          <span v-else>Save</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogNewSlide',
  data() {
    return {
      valid: false,
      progress: false,
      slide: {
        slideTitle: '',
        slideType: ''
      },
      slidesType: ['Slide Type 1', 'Slide Type 2', 'Slide Type 3'],
      rules: {
        slideTitle: [
          v => !!v || 'Slide Title is required!'
        ],
        slideType: [
          v => !!v || 'Layout Type is required!'
        ],
      }
    }
  },
  computed: {
    dialog() {
      return this.$store.state.GlobalModules.dialogNewSlide
    },
    user() {
      return this.$store.state.GlobalModules.user.user
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('closeDialogNewSlide')
      this.$refs.form.reset()
    },
    saveNewSlide() {      
      if (this.$refs.form.validate()) {
        this.progress = true
        const newSlide = {
          userId: this.user.userId,
          slideCollectionId: this.$route.params.presentationId,
          headline: this.slide.slideTitle
        }
        this.$store.dispatch('createNewSlide', {...newSlide}).then(() => {
          this.closeDialog()
          this.progress = false
        })
      }
    }
  }
}
</script>

<style scoped src='./style/index.css'/>